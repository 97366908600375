import React from 'react';

export default function AboutUs() {
  return <h1 className='About-us'>
    <ul>
        <li className='my-4 mx-5'>If you haven't notice pokemon card  background changing with its type like red for fire, green for grass ETC.</li>
        <li className='my-4 mx-5'>Api sending duplicate pokemon again and again sometimes. I have tried to resolve it but can't as this may be api issue but search component is totally fine.</li>
    </ul>
    <div>Thank You!! waiting for your response.</div>
  </h1>;
}