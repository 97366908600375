import React from 'react'

const Spinner = () =>{


        return (
            <div className="text-center">
                <img className="my-5" src='https://c.tenor.com/BtC0jVjzYToAAAAC/loading-chain.gif' style={{width:'200px'}}alt="Loading"/>
                {/* <img className="my-5" src={loading} alt="Loading"/> */}
            </div>
        )
    
}

export default Spinner